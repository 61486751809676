import React, { MouseEvent } from 'react';
import './style.scss';
import classnames from 'classnames';

export type LinkProps = {
  id?: string;
  href?: string;
  target?: '_self' | '_blank';
  label?: string;
  children?: string | JSX.Element | JSX.Element[];
  button?: boolean;
  className?: string;
  disabled?: boolean;
  title?: string;
  rel?: string;
  style?: React.CSSProperties;
  onClick?: (event: MouseEvent<any>) => void;
  absolutePath?: boolean;
};

const Link = ({
  id,
  href,
  disabled,
  target = '_self',
  label,
  children,
  button = false,
  className,
  title = '',
  onClick,
  rel,
  style,
  absolutePath = false
}: LinkProps) => {
  let url = href;

  if (!absolutePath) {
    if (label !== 'Apoio ao Cliente') {
      url =
        href !== 'https://www.intermarche.pt'
          ? href?.replace('https://www.intermarche.pt', '')
          : '/';
    }
  }

  let relData = rel?.trim();

  if (target === '_blank') {
    relData += ' noopener noreferrer';
  }

  return (
    <a
      id={id}
      rel={relData}
      title={title}
      className={classnames({
        link: true,
        'link--disabled': disabled,
        'link--button': button,
        [`${className}`]: className
      })}
      style={style}
      href={url ?? '#'}
      target={target}
      onClick={(e) => {
        if (disabled) {
          e.preventDefault();
        }
        if (onClick) {
          onClick(e);
        }
      }}
    >
      {label || children}
    </a>
  );
};

export default Link;
